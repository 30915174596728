.container-banner{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.61)),url("../../../images/monetizing-banner.jpg");
    /* background-position: center; */
    /* background-image:  url("../../../images/gbrPN/PNBanner.webp"); */
    background-repeat: no-repeat;
    background-size: cover;
    padding: 260px 0 180px;
    color: white;
    /* background-attachment: fixed; */
   /* position: relative; */
}

.container-banner .MuiButton-root{
    text-transform: none;
}
.container-banner h1{
    font-size: 28px;
    margin: 0 0 20px;
    line-height: 42px;
    font-weight: 600;
}

.abn-content{
    position: relative;
}

.container-banner p,
.update-banner-container p{
    font-size: 16px;
    margin:0 0 26px;
    line-height: 28px;
}
.container-banner Button,
.update-banner-container Button{
    padding: 10px 20px;
}

.container-banner Button a,
.update-banner-container a{
    text-decoration: none !important;
    color: white;
    font-size: 14px;
    line-height: 1;
}
/* .abn-content{
    margin-bottom: 30px;
} */
.overlay__bg{
    background-color: rgb(0 0 0 / 54%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(3px);
    padding: 20px;
    /* margin: 30px; */
    font-weight: bold;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

@media screen and (max-width:769px){
    .container-banner {
        padding: 150px 0 100px;
        background-position: center;
        /* background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.67)),url(/static/media/PNBanner.d29d2559.webp); */

    }
    .container-banner h1{
        font-size: 24px;
        margin:0 0 20px;
        line-height: 40px;
    }
    
    .container-banner p{
        font-size: 14px;
        margin:0 0 20px;
        line-height: 24px;
    }
    .container-banner Button{
        padding: 10px 20px;
    }
    .overlay__bg{
        background-color: rgba(0, 0, 0, 0.281);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(3px);
    }
}


@media screen and (max-width:500px){
 
    .container-banner Button{
        padding: 10px 10px;
    }
}


