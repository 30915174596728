.abn-container{
    padding: 30px 0 0px 0;
}
.abn-container .MuiButton-root{
    text-transform: none;
}
.networkImg{
    width: 50% !important;
    height: 50% !important;
}
.mob-vis{
    display: none !important;
    /* width: 100%;
    height: 100%; */
}
.mob-hide{
    display: inline-block !important;
    /* width: 100%;
    height: 100%; */
}

.abn-container img{
    width: 100%;
    height: auto;
}
.abn-container img:hover{
    cursor: initial !important;
}
.abn-container a{
    text-decoration: none;
    line-height: 23px;
    color: white;
}

.abn-container button{
    margin-top: 25px;
}
.mob-vis1{
    margin: 30px auto 0;
    width: 80%;
    height: auto;
    display: block;
}

.mob-vis2{
    margin: 30px auto 0;
    width: 100%;
    height: auto;
    display: block;
}
@media screen and (min-width:769px){
    .abn-container .container-para{
        padding: 0 150px;
    }
.mob-vis{
    margin: 30px auto 0;
    /* width: 60%; */
    display: inline-block !important;
}
.mob-hide{
    display: none !important;
}
}

@media screen and (max-width:769px){
    .abn-container{
        padding: 20px 0 0px 0;
    }
    .mob-vis1,.mob-vis2{
        width: 100%;
        height: auto;
    }
}