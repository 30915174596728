.storage, .farming, .selling, .transport{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("../../images/installation-bee-hives-new-location.jpg");
}

.processing{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("../../images/gbrHome/machinery.webp");

}
.moneyMakingSupport1{
    background-image: url("../../images/processingService/farming-capacity.jpg");
}

.moneyMakingSupport2{
    background-image: url("../../images/processingService/storage-capacity.jpg");
}

.transportSupport{
    background-image: url("../../images/generative-ai-has-developed-delivery-monitoring-system-modern-online-businesses-ecommerce-ensure-prompt-delivery-items.jpg");
}