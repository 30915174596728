@font-face {
  font-family: open-sans-Regular;
  font-display: swap;
  src: url("./font/Open_Sans/OpenSans/OpenSans-Regular.ttf");
}
/* *{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

body {
  margin: 0;
  /* font-family: NunitoSans Regular !important; */
  font-family: open-sans-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: open-sans-Regular;
}
.section3Col .iconCnt.counter {
  /* font-size: 30px; */
  padding-top: 28px;
  padding-bottom: 28px;
}
.section3Body {
  /* max-width: 200px; */
  border-radius: 10px;
  /* min-height: 100px; */
  padding: 20px;
  margin: 18px;
  box-shadow: 1.2px 1.2px 8px rgb(0 0 0 / 20%);
  /* box-shadow: 0 4px 5px 0 rgba(29, 29, 29, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.11); */
  text-align: center;
  /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.section3BodyWithoutPAdding {
  min-width: 100px;
  border-radius: 10px;
  padding: 1px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.section3BodyContainer {
  display: flex;
  padding: 10px 0 0;
  flex-wrap: wrap;
}

@media screen and (min-width:992px){
.IAA-mangement-wrapper .section3BodyContainer{
  flex-wrap: nowrap !important;
}
}
.IAA-mangement-wrapper .section3Body{
  margin: 18px 18px 0!important;

}


.arraowImage {
  align-items: center;
  display: flex;
}
.section3Body p {
  margin: 0;
}

@media screen and (max-width: 639px) {
  .section3BodyContainer {
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
  .arraowImage {
    transform: rotate(90deg);
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .section3BodyContainer {
    display: flex;
    padding: 10px 0;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .section3BodyContainer {
    display: flex;
    padding: 10px 0;
    flex-wrap: wrap;
  }
}

.cardStyleForDashboardItems {
  min-width: 100px;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  /* justify-content: space-between; */

  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.videosWrapper {
  width: 30% !important;
  margin: 1%;
  display: inline-flex;
  float: left;
  background-color: #fff;
  /* background-image: linear-gradient(to top, #D3D3D3, 50%, #537895 100%); */
}
.adminTendersDiv {
  min-height: 300px;
}

.homepageSlider {
  overflow-x: hidden;
}

.card {
  max-width: calc(100%-16px);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 12%);
  /* box-shadow: 0 4px 5px 0 rgba(49, 49, 49, 0.2), 0 2px 5px 0 rgba(49, 49, 49, 0.11); */
    text-align: center;
}

.img-thumbnail {
  box-sizing: border-box !important;
  width: 150px !important;
  height: 150px !important;
  /* border-radius: 20px !important; */
  /* border-color: #3ecf8e; */
}

.product-video {
  display: inline-flex;
  width: 100% !important;
  height: 150px !important;
  border-radius: 20px !important;
}

.product-videoimage {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

#videoSection {
  max-width: 100%;
  text-align: center;
}
#imagesSection {
  color: white;
  max-width: 100%;
  text-align: center;
}
#postsSection {
  color: white;
  display: inline;
  text-align: center;
}
.filterContent {
  display: inline;
  margin: 5px;
  padding: 10px;
}
.filterContent > input {
  margin-right: 5px;
}
.fast-transition {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  /* transition: all 0.3s ease-in-out; */
}

/*  .fast-transition::before {
   
   
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }
  
  .fast-transition:hover {
    transform: scale(1.2, 1.2);
  }
  .fast-transition:hover::after {
    opacity: 1;
  } */
.wrapperMain img,
.sectionImage {
  width: 100% !important;
  height: auto !important;
}
.storeButtons img,
.playStoreImage {
  width: 120px;
  height: auto;
}
.wrapperMain .MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 0;
}

#locationSection {
  padding-bottom: 50px;
  margin-top: 50px;
}
.apiTesting {
  color: whitesmoke;
}
.gridVideos {
  display: inline-flex;
}
.videosWrapper {
  display: inline;
}

#videoFile {
  margin-top: 20px;
  vertical-align: center;
  border-radius: 9px;
  padding: 18px;
  cursor: pointer;
}
.uploadBtn {
  margin-right: 10px;
  margin-bottom: 10px;
}

.serchFilterContainer {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}
.searchbar {
  float: left;
  margin-top: 25px;
  padding: 13px;
  width: 100%;
  border: 1px solid rgb(17, 19, 19);
  border-radius: 8px;
}
.filterBtn {
  float: right;
  margin-top: 30px;
  border-radius: 5px;
  padding: 5px;
}

/* Filter Box */
.filterBoxContainer {
  margin-bottom: 20px;
}

.applyBtn {
  margin: 10px 20px;
}
.cancelBtn {
  margin: 10px 20px;
}
#filterBox {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
/* homepageNavbar */
.homepageNavbar {
  margin-top: 20px;
  width: 100%;
}
.homepage-navbar {
  list-style: none;
  display: inline-flex;
  width: 100%;
}
.homepage-nav-item {
  padding: 10px;
}
.navbarLeft {
  float: left;
  padding: 10px;
}
.homepageBtn {
  color: white;
  background-color: #3ecf8e;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #3ecf8e;
}
.form-select{
  color: #808080;
}
.downloadDocument{
  display: inline-block;
  text-decoration: none;
  background-color: #3ecf8e;
  padding: 6px 16px;
  /* width: 100%; */
  border-radius: 6px;
  font-size: 16px;
  color: white !important;
  margin-top: 20px;
  border: 1px solid #3ecf8e;
}
.products-page-container {
  padding: 40px 0 40px;
}
/* .homepageBtn:hover {
  color: rgb(67, 163, 149);
}
.homepageBtn:focus {
  outline: rgb(67, 163, 149) !important ;
  border-color: rgb(67, 163, 149) !important ;
} */

.dropdown-menu li {
  background-color: rgb(201, 238, 232);
}
.homepage-dropdown-menu {
  background-color: rgb(67, 163, 149);
  border-color: rgb(67, 163, 149);
  padding: 1px;
}
.tenderHeading {
  float: left;
  margin: 20px 0px 20px 10px;
}
.tendersListDiv {
  margin-bottom: 30px;
}
.downloadDocument {
  cursor: pointer;
}
/* Pagination */
.paginationComponent {
  width: 100%;
  display: inline-flex;
  float: right;
  overflow: auto;
}
.pagination {
  margin: 20px auto 5px;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #3ecf8e;
  border-color: #3ecf8e;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #3ecf8e;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #3ecf8e;
  border-color: #3ecf8e;
  outline: none;
  color: white !important;
}
.pagination > li > a,
.pagination > li > span {
  color: #3ecf8e !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
#tendersContainer {
  overflow-x: hidden;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  margin-top: 10px;
  display: none;
  position: absolute;
  max-height: 250px;
  width: 250px;
  padding-left: 5px;
  overflow: scroll;
  border: none;
  background-color: #fff;
}
.dropdown-menu li {
  background-color: #aaadab !important;
  border: 1px solid #f5f5f5;
}
.homepage-dropdown-menu {
  background-color: rgb(67, 163, 149);
  border-color: rgb(67, 163, 149);
  padding: 1px;
}
.navbarRight {
  /* float: right; */
  display: inline-flex;
  width: 200px ;
  margin: 10px 10px 10px 0px;
}
.homepageNavbar select {
  width: 100%;
  padding: 11px 6px;
  background-color: 1px solid #808080 !important;
  color: #808080;
}
.update-wrapper {
  padding-top: 50px!important ;
  padding-bottom: 50px !important;
}
@media screen and (max-width: 769px) {
  .wrapperMain .SignUpasContainerWhite,
  .wrapperMain .SignUpasContainerLightGray {
    padding: 40px 16px 0;
  }
  .videosWrapper {
    width: 100% !important;
  }

  .update-wrapper {
    padding-top: 30px!important ;
    padding-bottom: 30px !important;  }
  .dropdown {
    width: 100%;
  }
  .navbarRight {
    float: none;
    width: 100% !important;
    margin:  10px 0;
  }
  .homepageBtn{
    width: 100% !important;
  }
}

.leftHeadings h5 {
  color: #000;
  font-size: 18px;
  margin: 10px 0;
}
.leftHeadings p {
  color: #808080;
}
.leftHeadings ul {
  padding: 0;
}

.aws-sld__content {
  height: 100vh;
}
.aws-sld__wrapper {
  height: 100vh;
}
.aws-sld__bullets {
  bottom: 16px;
  z-index: 99;
}
.MuiAppBar-colorPrimary {
  position: fixed !important;
  padding: 5px 0;
  /* box-shadow: none; */
}
.aws-sld__bullets button {
  background-color: rgb(255, 255, 255) !important;
  height: 12px;
  width: 12px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}
@media screen and (max-width: 769px) {
  /* .playStoreImage{
    margin-top: 10px;
  } */
  .aws-sld__content {
    height: 80vh;
  }
  .aws-sld__wrapper {
    height: 80vh;
  }
  .imageCnt {
    width: 80vw;
  }
  .appsGridSection {
    max-width: 80vw;
  }
  .aws-sld__bullets button {
    background-color: rgb(255, 255, 255) !important;

    height: 10px;
    width: 10px;
  }
}

@media screen and (max-width: 381px) {
  .playStoreImage {
    margin-top: 10px;
  }
  .playStoreImage--mod {
    margin-top: 0 !important;
  }
}

.imageCnt .investNowButton {
  padding: 16px;
  color: white !important;
}

.imageCnt a {
  color: white !important;
}
.googleplaystoreImg{
  width: 170px !important;
  height: auto !important;
}
@media screen and (min-width:769px){
  .gridSection--mmod{
    width: 30%;
  }
  }
@media (min-width: 1400px) {
  .MuiContainer-maxWidthLg {
    max-width: 85% !important;
    margin: 0 auto;
  }
  /* .MuiToolbar-regular{
    max-width: 90% !important;
    margin: 0 auto;
  } */
}
