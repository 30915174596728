.processingServiceBg{
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("../../images/processingService/banner.jpg");
}
.processingSupport1 {
  background-image: url("../../images/processingService/image1.jpg");
}
.processingSupport2 {
  background-image: url("../../images/processingService/image3.jpg");
}
.processingSupport3 {
  background-image: url("../../images/processingService/image2.jpg");
}
.processingSupport4 {
  background-image: url("../../images/processingService/image4.jpg");
}
.processingSupport5 {
  background-image: url("../../images/advisory.jpg");
}
.processingSupport6 {
  background-image: url("../../images/store-request-service-img.jpg");
}
.processingSupport7 {
  background-image: url("../../images/businessman-showing-franchise-system-mobile-device.jpg");
}
.LPaccount{
  background-image: url("../../images/medium-shot-man-checking-document.jpg");

}