.whiteTextColor {
    color: #FFF !important;
    text-transform:none !important;
    
}


.navTitle {
    flex-grow: 10;
}
/* .boxShadow{
    box-shadow: 0px 2px 5px rgb(0 0 0 / 50%);
} */
.gbr_header  a, .gbr_header button {
    color: #FFF;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500 ;
    padding: 0 14px;
}

.gbr_header button{
    font-weight: 500 !important;
    font-size: 16px !important;
    font-family: open-sans-Regular !important;
    line-height: 24px !important;
    padding-bottom: 2px;
}

.gbr_header button:hover{
    background: none !important;
}
.gbr_header .active{
    font-weight: 800;
}
.gbr_header  a:hover {
    font-weight: 600;
}

.drawer .whiteTextColor a, .drawer .navDrop  {
    color: #000;
    text-decoration: none;
    font-size: 19px;
    font-weight: 400;
}
.appsBtn{
    font-size: 18px;
    font-weight: 400;
}
.header-cross-icon{
    padding: 20px 0 0;
    position: absolute;
    right: 8px;
    z-index: 9;
    color: #3ECF8E;
}

.header-cross-icon .material-icons{
    font-size: 26px;
}
 
/* .logo a{
    color: #FFF !important;
    text-transform:none !important;
    font-size: 25px;
    font-weight: 800;
    float: left;
} */
@media screen and (min-width:769px){
    .mobile-hide{
        display: block;
    }
    .mobile-show{
        display: none;
    }
}


@media screen and (max-width: 992px) {
  
    .desktopNavbar{
        display: none;
    }
    .menuIcon{
        display: block;
    }
    .drawer{
        display: block;
    }
  

  }
 


  
@media screen and (min-width: 640px) {
    .MuiToolbar-gutters {
        padding-left: 34px !important;
        padding-right: 34px !important;
}
}

  @media screen and (min-width: 992px) {
    
    .desktopNavbar{
        display: block;
    }
    .menuIcon{
        display: none !important;
    }
    .drawer{
        display: none;
    }
  }
  .logo1{
    /* margin: 13px 0 0px; */
    /* width:200px;
    height:60px; */
    width: 100px !important;
    height: auto !important;
  }
  .MuiSvgIcon-root {
    height: 1.2em !important;
    width: 1.2em !important;
  }
  @media screen and (max-width:769px){
    .mobile-hide{
        display: none;
    }
    .mobile-show{
        display: block;
    }
    .logo1{
        /* margin: 3px 0 0 !important; */
        /* width:120px !important;
        height: auto !important; */
        width: 80px !important;
        height: auto !important;
      }
}

.sss{
    background-color: transparent !important;
}