.section3Body img {
  height: 45px;
  width: 45px;
}

.section3Body{
   transition:  width 2s, height 2s, transform 1s;
}
.section3Body img:hover {
  cursor: default !important;
}



.cards-container {
  display: flex;
  align-items: center;
}

.card-item {
  display: flex;
  align-items: center;
}

.card {
  padding: 20px;
  margin: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}







.process-container-img-container img{
  width: 100%;
  height: 70px;
}

.vertical-arrow{
  rotate: 90deg;
}

@media screen and (max-width:768px){
  .process-container{
    flex-direction: column;
    width: 100% !important;

  }
  .process-container-card{
    width: 100% !important;

  }
  .process-container-img-container img{
    rotate: 90deg;
    height: 50px;
  }
}

@media screen and (min-width:769px){
  .process-container{
    width: 33.33%;
  }
  .process-container-card{
    width: 75% !important;
  }
  
  
  .process-container-img-container{
    width: 20%;
  }

  .process-container-img-container-rev{
    rotate: -180deg;
  }
}