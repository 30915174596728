.financial-service-bg{
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../images/agriproducer-img/Services/businessman-counting-money-japanese-yen-banknotes-while-making-agreement-min.jpg") !important;
}

.loan-sevice{
  background-image: url("../../images/agriproducer-img/Services/loan/exchange-agreement-with-indian-rupees-min.jpg");
}